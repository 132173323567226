
export const parseSymbols = (instruments) => {

    let symbols = {};

    if(instruments && instruments.length > 0)
        for(let i = 0; i < instruments.length; i++)
            symbols[instruments[i].code] = []

    return symbols;
}