import {
  SET_BENEFICIARY_ROWS_FILTER,
  SET_BENEFICIARY_PAGES_FILTER,
  SET_BENEFICIARY_FILTER,
  CLEAR_STORE
} from '../actions/types';
  
const initialState = {
  filter: {
    currency: '',
    search: '',
    sort_order: 'ascending',
    take: 10,
    page: 1,
    sort_by: 'name'
  },
};

const beneficiariesFilters = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BENEFICIARY_FILTER:
      return {
          ...state,
          filter:{ ...state.filter, ...payload},
      };
    case SET_BENEFICIARY_ROWS_FILTER:
      return {
          ...state,
          filter:{ ...state.filter, take:payload },
      };        
    case SET_BENEFICIARY_PAGES_FILTER:
      return {
          ...state,
          filter:{ ...state.filter, page:payload },
      };  
    case CLEAR_STORE:
      return initialState
                
    default:
      return state;
  }
}

export default beneficiariesFilters;