import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react'

const LoadingScreen = () => {
  return (
    <Flex height='100vh' justifyContent='center' alignItems='center'>
      <Spinner size='xl' />
    </Flex>
  )
}

export default LoadingScreen;
