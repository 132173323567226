const CardBody = {
  baseStyle: props => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      fontSize: '24px',
      fontWeight: 'bold',
      color: props.colorMode === 'dark' ? '#fff' : 'lightGrayColor',
      padding: '22px',
      height: '100%',
      minH: '80px',
    }
  },
}

export const CardBodyComponent = {
  components: {
    CardBody,
  },
}
