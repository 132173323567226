import { SET_SESSION_EXPIRATION, SET_SESSION_IDLE, SET_SESSION_LAST_ACTIVITY_TIME } from './types';

export const setSessionExpiration = (session) => dispatch => {

    dispatch({
      type: SET_SESSION_EXPIRATION,
      payload: session
    });
}

export const setSessionIdle = (session) => dispatch => {

  dispatch({
    type: SET_SESSION_IDLE,
    payload: session
  });
}

export const updateActivityTime = () => dispatch => {

    dispatch({
      type: SET_SESSION_LAST_ACTIVITY_TIME
    });
}