import rootReducer from './reducers'
import browserHistory from './utils/browserHistory'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { configureStore } from '@reduxjs/toolkit'

const routerMiddleware = createRouterMiddleware(browserHistory)

const store = configureStore({
  reducer: rootReducer(browserHistory),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(
      routerMiddleware
    ),
})

export default store
