import axios from 'axios'
import { GET_OFX_ACCOUNTS, GET_OFX_ACCOUNTS_FAILURE } from './types'

import { WORLDESK_URL, header_config } from '../api/config'
import { notify } from './notifications'

export const getOfxAccounts = company_id => async (dispatch, getState) => {
  const skip = getState().identity.skipOfxReauthenticate

  if (skip) return

  try {
    const body = JSON.stringify({
      currencies: null,
      include_balances: true,
    })

    const res = await axios.post(
      WORLDESK_URL +
        `/ofx/api/accounts?companyid=${encodeURIComponent(company_id)}`,
      body,
      header_config
    )

    dispatch({
      type: GET_OFX_ACCOUNTS,
      payload: res.data,
    })
  } catch (err) {
    dispatch({ type: GET_OFX_ACCOUNTS_FAILURE })
    dispatch(notify('Failed to get List of Ofx Virtual Accounts', 'error'))
  }
}
