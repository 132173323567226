import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
//import { HashRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
//Redux
import { Provider } from 'react-redux';
import store from './store';
import { ReduxRouter } from '@lagunovsky/redux-react-router'

import browserHistory from './utils/browserHistory'


const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ReduxRouter history={browserHistory}>
      <App />
    </ReduxRouter>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
