import { GET_OFX_ACCOUNTS, GET_OFX_ACCOUNTS_FAILURE } from '../actions/types'

const initialState = {
  virtual_accounts: [],
  loading: true,
  isFailed: false,
}

const ofxAccounts = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_OFX_ACCOUNTS:
      return {
        ...state,
        virtual_accounts: payload,
        itemsLength: payload.length,
        loading: false,
        isFailed: false,
      }
    case GET_OFX_ACCOUNTS_FAILURE:
      return {
        ...state,

        isFailed: true,
        loading: false,
      }
    default:
      return state
  }
}

export default ofxAccounts
