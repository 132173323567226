import {
    SET_CONVERSION_FILTER,
    CLEAR_CONVERSION_FILTER,
    SET_ROWS_FILTER,
    SET_PAGES_FILTER,
    CLEAR_STORE

} from '../actions/types';
  
const initialState = {
  filter:{
    sell_currency: '',
    buy_currency: '',
    min_amount: '',
    max_amount: '',
    start_date: '',
    end_date: '',
    sort_order: 'descending',
    state: [],
    take: 10,
    page: 1,
    sort_by: 'createdAt',
  },
  };

const conversionsFilter = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case SET_CONVERSION_FILTER:
        return {
            ...state,
            filter:{ ...state.filter, ...payload},
        };
      case SET_ROWS_FILTER:
        return {
            ...state,
            filter:{ ...state.filter, take:payload},
        };        
      case SET_PAGES_FILTER:
        return {
            ...state,
            filter:{ ...state.filter, page:payload },
        };  
      case CLEAR_CONVERSION_FILTER:
        return {
            ...state,
            filter:payload,
        };  
      case CLEAR_STORE:
        return initialState
                  
      default:
        return state;
    }
}

export default conversionsFilter;