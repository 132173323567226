import {
  GET_PAYMENTS,
  GET_PAYMENT_DETAILS,
  FUND_WALLET,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  GET_NONE_PAYMENT_DATES,
  SET_PAYMENT_LOADING,
  CLEAR_PAYMENTS,
  CLEAR_ITEMS,
  SET_PAYMENT_PARAMS,
  GET_PAYMENTS_ERROR,
  CLEAR_PAYMENT_DETAILS,
  CLEAR_STORE,
  DELETE_TABLE_ROW_REQUEST,
  DELETE_TABLE_ROW_SUCCESS,
  DELETE_TABLE_ROW_FAILURE,
  SET_PAYMENTPAGES_FILTER,
} from '../actions/types'

const initialState = {
  pages: 1,
  page: 1,
  payments: [],
  loading: true,
  savePaymentLoading: false,
  detailsLoading: true,
  isError: false,
  errorMessage: '',
  createPayment: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    errorMessage: '',
  },
  payment_details: {
    loading: true,
    id: '',
    friendly_id: '',
    reference: '',
    currency_provider: '',
    payment_type: '',
    beneficiary_reference: '',
    beneficiary_name: '',
    state: '',
    status: '',
    name: '',
    created_by: '',
    currency: '',
    amount: 0,
    created_at: '',
    scheduled_at: '',
    updated_at: '',
  },
  fund_wallet: false,
  result: {
    id: '',
    state: '',
  },
  non_payment_dates: {
    currency: '',
    dates: [],
    priority_cut_off: '',
    regular_cut_off: '',
  },
  payment_params: {
    name: '',
    reference: '',
    amount: '',
    schedule: '',
    min_date: new Date(),
    sell_currency: '',
    buy_currency: '',
    fixed_side: 'buy',
    sell_amount: 0,
    buy_amount: 0,
    balance: 10000,
    reason_id: 34,
    type: 'priority',
    beneficiary_id: '',
    set_date: false,
    bill_id: null,
  },
}

const transfers = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_PAYMENTPAGES_FILTER:
      return {
        ...state,

        page: payload,
      }
    case DELETE_TABLE_ROW_REQUEST:
      return {
        ...state,

        payments: state.payments.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: true,
              isDeleted: false,
              isDeleteFailed: false,
            }
          } else {
            return item
          }
        }),
      }
    case DELETE_TABLE_ROW_SUCCESS:
      return {
        ...state,

        payments:
          payload.state === 'failed' || payload.state === 'canceled'
            ? state.payments.filter(item => item.id !== payload.id)
            : state.payments.map(item => {
                if (item.id === payload.id) {
                  return {
                    ...item,

                    isDeleting: false,
                    isDeleted: false,
                    isDeleteFailed: false,
                    state: 'canceled',
                  }
                } else {
                  return item
                }
              }),
      }
    case DELETE_TABLE_ROW_FAILURE:
      return {
        ...state,

        payments: state.payments.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: false,
              isDeleted: false,
              isDeleteFailed: true,
            }
          } else {
            return item
          }
        }),
      }
    case GET_PAYMENTS:
      return {
        ...state,
        payments: payload.items,
        itemsLength: payload.items.length,
        pages: payload.total_pages === 0 ? 1 : payload.total_pages,
        loading: false,
      }
    case GET_PAYMENTS_ERROR:
      return {
        ...state,
        loading: false,
        savePaymentLoading: false,
      }
    case GET_PAYMENT_DETAILS:
      return {
        ...state,
        payment_details: payload,
        payments: state.payments.find(item => item.id === payload.id)
          ? state.payments.map(item => {
              if (payload.id === item.id) {
                return {
                  ...item,

                  ...payload,
                }
              } else {
                return item
              }
            })
          : [payload, ...state.payments],
        detailsLoading: false,
      }
    case CREATE_PAYMENT_REQUEST:
      return {
        ...state,

        createPayment: {
          ...state.createPayment,

          isFetching: true,
          isFetched: false,
          isFailed: false,
          errorMessage: '',
        },
      }
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        result: payload,
        payments: [payload, ...state.payments],
        loading: false,
        isError: false,
        errorMessage: '',
        savePaymentLoading: false,
        createPayment: {
          ...state.createPayment,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          errorMessage: '',
        },
      }
    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        isError: true,
        errorMessage: payload.errorMessage,
        loading: false,
        savePaymentLoading: false,
        payments: payload.id ? [payload, ...state.payments] : state.payments,
        createPayment: {
          ...state.createPayment,

          isFetching: false,
          isFetched: true,
          isFailed: true,
          errorMessage: payload.errorMessage,
        },
      }
    case GET_NONE_PAYMENT_DATES:
      return {
        ...state,
        non_payment_dates: payload,
        loading: false,
      }
    case SET_PAYMENT_LOADING:
      return {
        ...state,
        savePaymentLoading: payload,
        isError: false,
        errorMessage: '',
      }
    case SET_PAYMENT_PARAMS:
      return {
        ...state,
        payment_params: { ...state.payment_params, ...payload },
      }
    case FUND_WALLET:
      return {
        ...state,
        fund_wallet: payload,
      }
    case CLEAR_PAYMENTS:
      return {
        ...state,
        ...initialState,
      }
    case CLEAR_ITEMS:
      return {
        ...state,
        payments: [],
        loading: true,
      }
    case CLEAR_PAYMENT_DETAILS:
      return {
        ...state,
        detailsLoading: true,
        payment_params: initialState.payment_params,
        payment_details: {
          id: '',
          friendly_id: '',
          reference: '',
          currency_provider: '',
          payment_type: '',
          beneficiary_reference: '',
          beneficiary_name: '',
          state: '',
          status: '',
          name: '',
          created_by: '',
          currency: '',
          amount: 0,
          created_at: '',
          scheduled_at: '',
          updated_at: '',
        },
      }
    case CLEAR_STORE:
      return { ...initialState, ...state.payments }
    default:
      return state
  }
}

export default transfers
