import {
  GET_REASONS,
  GET_REASONS_CACHE,
  GET_CURRENCIES,
  GET_CURRENCIES_CACHE,
  GET_CURRENCIES_PINGPONG,
  GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG,
  GET_CURRENCIES_CAMBRIDGE,
  GET_OFX_CONVERSON_CURRENCIES,
  GET_OFX_PAYMENT_CURRENCIES,
  GET_OFX_CLASSIFICATION,
} from '../actions/types'
import { checkFlag } from '../utils/countryFlags'

const initialState = {
  reasons: [{ id: 34, value: 'Payroll' }],
  instruments: [
    {
      id: 36,
      currency_provider: 'moneyCorp',
      code: 'USD',
      name: 'United States Dollar',
      symbol: '$',
      decimal_digits: 2,
      flag: 'US',
    },
    {
      id: 10,
      currency_provider: 'moneyCorp',
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
      decimal_digits: 2,
      flag: 'EU',
    },
  ],
  pingpong_currencies: [],
  pingpong_currencies_countries: [],
  cambridge_currencies: [],
  ofx_conversion_currencies: [],
  ofx_payment_currencies: [],
  ofx_classification: {},
}

const references = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_REASONS:
      localStorage.setItem('reasons', JSON.stringify(payload))
      localStorage.setItem('reasons_last_update', new Date())
      return {
        ...state,
        reasons: payload,
      }
    case GET_REASONS_CACHE:
      return {
        ...state,
        reasons: payload,
      }
    case GET_CURRENCIES:
      localStorage.setItem('instruments', JSON.stringify(payload))
      localStorage.setItem('instruments_last_update', new Date())
      return {
        ...state,
        instruments: checkFlag(payload),
      }
    case GET_CURRENCIES_CACHE:
      return {
        ...state,
        instruments: checkFlag(payload),
      }
    case GET_CURRENCIES_PINGPONG:
      return {
        ...state,
        pingpong_currencies: payload,
      }
    case GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG:
      return {
        ...state,
        pingpong_currencies_countries: payload,
      }
    case GET_CURRENCIES_CAMBRIDGE:
      return {
        ...state,
        cambridge_currencies: payload,
      }
    case GET_OFX_CONVERSON_CURRENCIES:
      return {
        ...state,
        ofx_conversion_currencies: payload,
      }
    case GET_OFX_PAYMENT_CURRENCIES:
      return {
        ...state,
        ofx_payment_currencies: payload,
      }
    case GET_OFX_CLASSIFICATION:
      return {
        ...state,
        ofx_classification: payload,
      }
    default:
      return state
  }
}
export default references
