import {
  GET_BENEFICIARIES,
  GET_ALL_BENEFICIARIES,
  FETCH_BENEFICIARY_DETAILS_REQUEST,
  FETCH_BENEFICIARY_DETAILS_SUCCESS,
  FETCH_BENEFICIARY_DETAILS_FAILURE,
  CREATE_BENEFICIARY_REQUEST,
  CREATE_BENEFICIARY_SUCCESS,
  CREATE_BENEFICIARY_FAILURE,
  CLEAR_BENEFICIARY_DETAILS,
  CLEAR_BENEFICIARIES,
  TOGGLE_BENEFICIARY_LOADING,
  TOGGLE_BENEFICIARY_CREATING,
  FETCH_BENEFICIARIES_BY_SYM_REQUEST,
  FETCH_BENEFICIARIES_BY_SYM_SUCCESS,
  FETCH_BENEFICIARIES_BY_SYM_FAILURE,
  SET_BENEFICIARY_SEARCH,
  SET_BENEFICIARY_CURRENCY_FILTER,
  INIT_PAYMENT_BENEFICIARIES,
  CLEAR_ITEMS,
  CLEAR_STORE,
  DELETE_TABLE_ROW_REQUEST,
  DELETE_TABLE_ROW_SUCCESS,
  DELETE_TABLE_ROW_FAILURE,
  RESET_FORM,
  SET_BENEFICIARY_PAGES_FILTER,
} from '../actions/types'

const initialState = {
  detailsLoading: true,
  loading: true,
  errorMessage: '',
  creating: false,
  take: 10,
  page: 1,
  pages: 1,
  all_beneficiaries: [],
  payment_beneficiaries: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    items: null,
  },
  beneficiaries: [],
  beneficiary: {},
  beneficiaryState: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
  },
  pingpong_templates: {},
  searchString: '',
  currencyFilter: 'ALL',
  createBeneficiary: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    errorMessage: '',
    templateErrors: [],
  },
}

const beneficiaries = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_BENEFICIARY_PAGES_FILTER:
      return {
        ...state,

        page: payload,
      }
    case DELETE_TABLE_ROW_REQUEST:
      return {
        ...state,

        beneficiaries: state.beneficiaries.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: true,
              isDeleted: false,
              isDeleteFailed: false,
            }
          } else {
            return item
          }
        }),
      }
    case DELETE_TABLE_ROW_SUCCESS:
      return {
        ...state,

        beneficiaries: state.beneficiaries.filter(
          item => item.id !== payload.id
        ),
      }
    case DELETE_TABLE_ROW_FAILURE:
      return {
        ...state,

        beneficiaries: state.beneficiaries.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: false,
              isDeleted: false,
              isDeleteFailed: true,
            }
          } else {
            return item
          }
        }),
      }
    case INIT_PAYMENT_BENEFICIARIES:
      return {
        ...state,
        payment_beneficiaries: {
          ...state.payment_beneficiaries,

          items: payload,
        },
      }
    case GET_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: payload.items,
        itemsLength: payload.items.length,
        pages: payload.total_pages,
        loading: false,
      }
    case GET_ALL_BENEFICIARIES:
      return {
        ...state,
        all_beneficiaries: payload.items,
        loading: false,
      }
    case FETCH_BENEFICIARIES_BY_SYM_REQUEST:
      return {
        ...state,
        payment_beneficiaries: {
          ...state.payment_beneficiaries,

          isFetched: false,
          isFailed: false,
          isFetching: true,
        },
      }
    case FETCH_BENEFICIARIES_BY_SYM_SUCCESS:
      return {
        ...state,
        payment_beneficiaries: {
          ...state.payment_beneficiaries,

          isFetched: true,
          isFailed: false,
          isFetching: false,
          items: {
            ...state.payment_beneficiaries.items,

            [payload.currency]: payload.items,
          },
        },
        loading: false,
      }
    case FETCH_BENEFICIARIES_BY_SYM_FAILURE:
      return {
        ...state,
        payment_beneficiaries: {
          ...state.payment_beneficiaries,

          isFetched: true,
          isFailed: true,
          isFetching: false,
        },
      }
    case FETCH_BENEFICIARY_DETAILS_REQUEST:
      return {
        ...state,

        beneficiaryState: {
          ...state.beneficiaryState,

          isFetching: true,
          isFetched: false,
          isFailed: false,
        },
      }
    case FETCH_BENEFICIARY_DETAILS_SUCCESS:
      return {
        ...state,
        beneficiary: payload,
        beneficiaries: state.beneficiaries.find(item => item.id === payload.id)
          ? state.beneficiaries.map(item => {
              if (payload.id === item.id) {
                return {
                  ...item,

                  ...payload,
                }
              } else {
                return item
              }
            })
          : [payload, ...state.beneficiaries],
        loading: false,
        detailsLoading: false,
        beneficiaryState: {
          ...state.beneficiaryState,

          isFetched: true,
          isFailed: false,
          isFetching: false,
        },
      }
    case FETCH_BENEFICIARY_DETAILS_FAILURE:
      return {
        ...state,

        beneficiaryState: {
          ...state.beneficiaryState,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    case CREATE_BENEFICIARY_REQUEST:
      return {
        ...state,

        createBeneficiary: {
          ...state.createBeneficiary,

          isFetching: true,
          isFetched: false,
          isFailed: false,
          errorMessage: '',
          templateErrors: [],
        },
      }
    case CREATE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        beneficiaries: [payload, ...state.beneficiaries],
        all_beneficiaries: [payload, ...state.beneficiaries],
        loading: false,
        creating: false,
        errorMessage: '',
        createBeneficiary: {
          ...state.createBeneficiary,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          errorMessage: '',
          templateErrors: [],
        },
      }
    case CREATE_BENEFICIARY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        createBeneficiary: {
          ...state.createBeneficiary,

          isFetching: false,
          isFetched: true,
          isFailed: true,
          errorMessage: payload.errorMessage,
          templateErrors: payload.templateErrors,
        },
      }
    case TOGGLE_BENEFICIARY_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case TOGGLE_BENEFICIARY_CREATING:
      return {
        ...state,
        creating: payload,
      }
    case SET_BENEFICIARY_SEARCH:
      return {
        ...state,
        searchString: payload,
      }
    case SET_BENEFICIARY_CURRENCY_FILTER:
      return {
        ...state,
        currencyFilter: payload,
      }
    case CLEAR_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: [],
        beneficiary: null,
        loading: true,
      }
    case CLEAR_ITEMS:
      return {
        ...state,
        beneficiaries: [],
        beneficiary: null,
        loading: true,
      }
    case CLEAR_BENEFICIARY_DETAILS:
      return {
        ...state,
        beneficiary: {},
        detailsLoading: true,
      }
    case RESET_FORM:
      return {
        ...state,

        createBeneficiary: initialState.createBeneficiary,
      }
    case CLEAR_STORE:
      return initialState

    default:
      return state
  }
}

export default beneficiaries
