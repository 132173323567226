function getENV() {
  if (
    document.getElementById('env') &&
    document.getElementById('env').textContent
  ) {
    return JSON.parse(document.getElementById('env').textContent)
  } else {
    return {
      APPLICATION_CLIENT_ID: 'mosaica.web_application',
      WORLDESK_GATEWAY: 'https://gateway.dev.worldesk.net',
      WORLDESK_SIGNALR:
        'https://notifications-api.purplesand-b59b2a43.westeurope.azurecontainerapps.io/notifications',
      WORLDESK_ENV: 'development',
      DD_SESSION_REPLAY_SAMPLE_RATE: 20,
      RECAPTCHA_V2_KEY: '6LcCWX0fAAAAAGufAVzkSJcJaFLBz9EA11pSYGoQ',
      RECAPTCHA_V3_KEY: '6Lc5A3UfAAAAABBMVTSe4Un9D_ORrHVFPXZA2EXl',
      TOUR_ENABLED: 'true',
      version: '0.0.1',
    }
  }
}

export default getENV
