import axios from 'axios'
import {
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  ONBOARD_LOADING,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAIL,
  PHONE_VERIFY_SUCCESS,
  PHONE_VERIFY_FAIL,
  SET_EMAIL,
  RECAPTCHA_FAIL_REGISTER,
  INITIALTE_PHONE_VERIFY_REQUEST,
  INITIALTE_PHONE_VERIFY_SUCCESS,
  INITIALTE_PHONE_VERIFY_FAILURE,
} from './types'
import { WORLDESK_URL, header_config } from '../api/config'
import { notify } from './notifications'
import { checkErrorStatus } from './error'

export const register =
  ({ first_name, last_name, email, phone_number, password, recaptcha }) =>
  async dispatch => {
    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      phone_number,
      recaptcha,
    })

    try {
      const res = await axios.post(
        WORLDESK_URL + '/identity/api/account/register',
        body,
        header_config
      )

      const { email_confirmed, phone_confirmed, errors } = res.data

      if (errors.length < 1 && !email_confirmed && !phone_confirmed) {
        dispatch({
          type: USER_CREATE_SUCCESS,
          payload: {
            userEmail: email,
            userPhone: phone_number,
          },
        })
        dispatch(
          notify(
            `Code sent to ${email}. Please check your emails including your spam folder.`,
            'info'
          )
        )
      }
    } catch (err) {
      dispatch(checkErrorStatus(err))
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.length > 0
      ) {
        err.response.data.errors.forEach(err => dispatch(notify(err, 'error')))
      } else if (err.response && err.response.data) {
        if (err.response.data.code) {
          if (err.response.data.code === 'sec:8') {
            dispatch({
              type: RECAPTCHA_FAIL_REGISTER,
              payload: recaptcha.version,
            })
          } else {
            dispatch({
              type: USER_CREATE_FAIL,
              payload: err.response.data.error,
            })
          }
        } else {
          dispatch(notify(err.response.data, 'error'))
          dispatch({
            type: USER_CREATE_FAIL,
            payload:
              typeof err.response.data === 'string' ? err.response.data : '',
          })
        }
      } else {
        dispatch(
          notify(
            'Registration failed. The application has encountered an error.',
            'error'
          )
        )
        dispatch({
          type: USER_CREATE_FAIL,
          payload:
            'Registration failed. The application has encountered an error.',
        })
      }
    }
  }

export const emailVerify =
  ({ email, code }) =>
  async dispatch => {
    const body = JSON.stringify({ email, code })

    try {
      const res = await axios.post(
        WORLDESK_URL + '/identity/api/AccountValidation/validate/email',
        body,
        header_config
      )

      const { email_confirmed } = res.data
      if (email_confirmed) {
        dispatch({ type: EMAIL_VERIFY_SUCCESS })
        dispatch(notify('Email Verified', 'success'))
      }
    } catch (err) {
      dispatch(checkErrorStatus(err))
      dispatch({ type: EMAIL_VERIFY_FAIL })

      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.Email
      ) {
        dispatch(notify(err.response.data.errors.Email[0], 'error'))
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.Code
      ) {
        dispatch(notify(err.response.data.errors.Code[0], 'error'))
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        dispatch(notify(err.response.data.message, 'error'))
      } else {
        dispatch(notify('Email verification failed.', 'error'))
      }
    }
  }

export const initiateEmailVerify = email => async dispatch => {
  const body = JSON.stringify({ email })

  try {
    await axios.post(
      WORLDESK_URL + '/identity/api/AccountValidation/initiate/email',
      body,
      header_config
    )
    dispatch(
      notify(
        `Code sent to ${email}. Please check your emails including your spam folder.`,
        'info'
      )
    )
  } catch (err) {
    dispatch(checkErrorStatus(err))
    if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data.errors &&
      err.response.data.errors.Email
    ) {
      dispatch(notify(err.response.data.errors.Email[0], 'error'))
    } else if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data.message
    ) {
      dispatch(notify(err.response.data.message, 'error'))
    } else if (err && err.response && err.response.data) {
      dispatch(notify(err.response.data, 'error'))
    } else {
      dispatch(notify('Application has encountered an error.', 'error'))
    }
  }
}

export const phoneVerify =
  ({ email, code }) =>
  async dispatch => {
    const body = JSON.stringify({ email, code })

    try {
      const res = await axios.post(
        WORLDESK_URL + '/identity/api/AccountValidation/validate/phone',
        body,
        header_config
      )
      const { phone_confirmed } = res.data
      if (phone_confirmed) {
        dispatch({ type: PHONE_VERIFY_SUCCESS })
        dispatch(notify('Phone verified.'), 'success')
        dispatch(notify('Registration Successful.'), 'success')
      }
    } catch (err) {
      dispatch({ type: PHONE_VERIFY_FAIL })
      dispatch(checkErrorStatus(err))
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.Email
      ) {
        err.response.data.errors.Email.forEach(err =>
          dispatch(notify(err, 'error'))
        )
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.PhoneNumber
      ) {
        err.response.data.errors.PhoneNumber.forEach(err =>
          dispatch(notify(err, 'error'))
        )
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.Code
      ) {
        err.response.data.errors.Code.forEach(err =>
          dispatch(notify(err, 'error'))
        )
      } else if (err && err.response && err.response.data.message) {
        dispatch(notify(err.response.data.message, 'error'))
      } else {
        dispatch(notify('Phone Verification failed.', 'error'))
      }
    }
  }

export const initiatePhoneVerify =
  ({ phone_number, email }) =>
  async dispatch => {
    const body = JSON.stringify({ phone_number, email })

    try {
      dispatch({ type: INITIALTE_PHONE_VERIFY_REQUEST })
      await axios.post(
        WORLDESK_URL + '/identity/api/AccountValidation/initiate/phone',
        body,
        header_config
      )
      dispatch({ type: INITIALTE_PHONE_VERIFY_SUCCESS })
      dispatch(notify(`SMS code sent to ${phone_number}`, 'info'))
    } catch (err) {
      dispatch(checkErrorStatus(err))
      dispatch({ type: INITIALTE_PHONE_VERIFY_FAILURE })
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.Email
      ) {
        err.response.data.errors.Email.forEach(err =>
          dispatch(notify(err, 'error'))
        )
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.PhoneNumber
      ) {
        err.response.data.errors.PhoneNumber.forEach(err =>
          dispatch(notify(err, 'error'))
        )
      } else if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        dispatch(notify(err.response.data.message, 'error'))
      } else if (err && err.response && err.response.data) {
        dispatch(notify(err.response.data, 'error'))
      } else {
        dispatch(notify('Application has encountered an error.', 'error'))
      }
    }
  }

export const setOnBoardLoading = isloading => dispatch => {
  dispatch({
    type: ONBOARD_LOADING,
    payload: isloading,
  })
}

export const setUserEmail = email => dispatch => {
  dispatch({
    type: SET_EMAIL,
    payload: email,
  })
}
