const CardHeader = {
  baseStyle: props => {
    return {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '74px',
      fontWeight: 'bold',
      fontSize: '16px',
      backgroundColor: props.colorMode === 'dark' ? 'gray.700' : 'grayColor',
      color: props.colorMode === 'dark' ? 'lightGrayColor' : 'gray.1000',
      borderBottomWidth: '3px',
      borderColor: '#008CFF',
    }
  },
}

export const CardHeaderComponent = {
  components: {
    CardHeader,
  },
}
