import theme from './theme'
import identity from './identity'
import alert from './alert'
import onboarding from './onboarding'
import userinfo from './userinfo'
import company from './company'
import currencyProvider from './currencyProvider'
import beneficiaries from './beneficiaries'
import beneficiaryTemplates from './beneficiaryTemplates'
import notifications from './notifications'
import conversions from './conversions'
import conversionFilters from './conversionFilters'
import payments from './payments'
import accounts from './accounts'
import balances from './balances'
import paymentFilters from './paymentFilters'
import lockrates from './lockrates'
import lockrateFilters from './lockrateFilters'
import overview from './overview'
import automations from './automations'
import automationFilters from './automationFilters'
import bills from './bills'
import billFilters from './billFilters'
import beneficiariesFilters from './beneficiariesFilters'
import quickbooks from './quickbooks'
import init from './init'
import session from './session'
import references from './references'
import receiving from './receiving'
import cambridgeAccounts from './cambridgeAccounts'
import ofxAccounts from './ofxAccounts'
import routerReducer from './router'
import signalR from './signalR'
import members from './members'
import clientOnboarding from './clientOnboarding'
import correlationId from './correlationId'

const rootReducer = history => ({
  router: routerReducer,
  theme,
  identity,
  alert,
  onboarding,
  userinfo,
  company,
  currencyProvider,
  beneficiaries,
  beneficiaryTemplates,
  notifications,
  conversions,
  conversionFilters,
  payments,
  accounts,
  balances,
  paymentFilters,
  lockrates,
  lockrateFilters,
  overview,
  automations,
  automationFilters,
  bills,
  quickbooks,
  billFilters,
  beneficiariesFilters,
  init,
  session,
  references,
  receiving,
  cambridgeAccounts,
  ofxAccounts,
  signalR,
  members,
  clientOnboarding,
  correlationId,
})

export default rootReducer
