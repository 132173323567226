import moment from 'moment';

const isTokenValid = () => {

    let expirationTime = localStorage.getItem('expiration_time');

    if(moment.unix(expirationTime) > moment()){
        return true;
    } else {
        return false;
    }
}

export default isTokenValid;