import {
  SET_BILL_FILTER,
  SET_BILL_ROWS_FILTER,
  SET_BILL_PAGES_FILTER,
  CLEAR_STORE
} from '../actions/types';
  
const initialState = {
  filter:{  
    currency: '',
    overdue: '',
    min_amount: '',
    max_amount: '',
    start_date: '',
    end_date: '',
    sort_order: 'descending',
    state:[],
    take: 10,
    page: 1,
    sort_by: 'dueAt'
  },
};

const billFilters = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BILL_FILTER:
      return {
          ...state,
          filter:{ ...state.filter, ...payload},
      };
    case SET_BILL_ROWS_FILTER:
      return {
          ...state,
          filter:{ ...state.filter, take:payload },
      };        
    case SET_BILL_PAGES_FILTER:
      return {
          ...state,
          filter:{ ...state.filter, page:payload },
      };  
    case CLEAR_STORE:
      return initialState
    default:
      return state;
  }
}

export default billFilters;