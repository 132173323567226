import {
  GET_OVERVIEW,
  CLEAR_OVERVIEW,
  GET_OVERVIEW_ERROR,
  SET_OVERVIEW_LOADING,
  FETCH_OVERVIEW_REQUEST,
} from '../actions/types'

const initialState = {
  fetching: null,
  overview: {
    required_funds: 0,
    amount_at_risk: {
      locked: 0,
      at_risk: 0,
      secured: 0,
      at_risk_percentage: 0,
      secured_percentag: 0,
    },
    funds: {
      balances: [],
      total_amount: 0,
    },
    bills: {
      due: {
        count: 0,
        balance: 0,
        pending: 0,
        bills: [],
      },
      past_due: {
        count: 0,
        balance: 0,
        pending: 0,
        bills: [],
      },
    },
    lock_rates: {
      count: 0,
      total_amount: 0.0,
      due: [],
    },
  },
  loading: true,
  report_date: new Date(),
}

const overview = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_OVERVIEW_REQUEST:
      return {
        ...state,

        fetching: payload,
      }
    case GET_OVERVIEW:
      if (
        payload.fetching.company_id === state.fetching.company_id &&
        payload.fetching.providerSelected === state.fetching.providerSelected
      ) {
        return {
          ...state,
          overview: payload.data,
          report_date: new Date(),
          loading: false,
        }
      } else {
        return state
      }
    case CLEAR_OVERVIEW:
      return { ...initialState, fetching: state.fetching }
    case GET_OVERVIEW_ERROR:
      return {
        ...state,
        loading: false,
      }
    case SET_OVERVIEW_LOADING:
      return {
        ...state,
        loading: payload,
      }
    default:
      return state
  }
}

export default overview
