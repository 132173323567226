import React from 'react';
import { useSelector } from 'react-redux';

import {
  Alert,
  AlertIcon,
  Box
} from '@chakra-ui/react'

const Alerts = () => {
  const alerts = useSelector(state => state.alert);
  return (
    <>
      {alerts !== null && alerts.length > 0 && alerts.map(alert => {
        return (
          <Box 
            minW='200px' 
            maxW='600px'
            margin='auto' 
            position='fixed' 
            bottom='20px'
            right='30px'
            zIndex='10'
          >
            <Alert key={alert.id} id={alert.id} status={alert.alertType} data-role={alert.alertType} variant='left-accent'>
              <AlertIcon />
              {alert.msg}
            </Alert>
          </Box>
        )
      })}
    </>
  );
}

export default Alerts;