import {
    GET_RECEIVING_ACCOUNTS,
    GET_RECEIVING_ACCOUNTS_TEMPLATES,
    RECEIVING_ACCOUNTS_LOADING,
    GET_RECEIVING_ACCOUNTS_ERROR
} from '../actions/types';
  
const initialState = {
    receving_accounts:[],
    receiving_accounts_templates:[],
    loading: true
};

const accounts = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
        case GET_RECEIVING_ACCOUNTS:
            return {
                ...state,
                receving_accounts:payload,
                loading:false
            }; 
        case GET_RECEIVING_ACCOUNTS_TEMPLATES:
            return {
                ...state,
                receiving_accounts_templates:payload,
                loading:false
            };
        case GET_RECEIVING_ACCOUNTS_ERROR:
            return {
                ...state,
                loading:false
            };
        case RECEIVING_ACCOUNTS_LOADING:
            return {
                ...state,
                loading:payload
            };
        
    default:
        return state;
    }
}

export default accounts;