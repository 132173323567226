import {
  GETCOMPANIES,
  CREATE_COMPANY,
  ADD_COMPANY,
  COMPANY_DETAILS,
  UPDATE_COMPANY,
  TOGGLE_EDIT,
  CLEAR_COMPANY_INFO,
  TOGGLE_COMPANY_LOADING,
  GET_COMPANY_DETAILS_REQUEST,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAILURE,
  DISCONNECT_OFX,
  DISCONNECT_CAMBRIDGE,
} from '../actions/types'

const initialState = {
  company: [],
  supportedProviders: [],
  companies: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    skipLoading: false,
  },
  company_details: {},
  loading: true,
  isEdit: false,
  addedCompanyId: '',
}

const company = (state = initialState, action) => {
  const { type, payload } = action
  const getSupported = integrations => {
    if (integrations) {
      return integrations.supported.map(item => item.toLowerCase())
    }
    return []
  }
  switch (type) {
    case DISCONNECT_CAMBRIDGE:
      return {
        ...state,
        company_details: {
          ...state.company_details,
          integrations: {
            ...state.company_details.integrations,

            currency_providers:
              state.company_details.integrations.currency_providers.filter(
                item => item.type !== 'cambridge'
              ),
          },
        },
      }
    case DISCONNECT_OFX:
      return {
        ...state,
        company_details: {
          ...state.company_details,
          integrations: {
            ...state.company_details.integrations,

            currency_providers:
              state.company_details.integrations.currency_providers.filter(
                item => item.type !== 'ofx'
              ),
          },
        },
      }
    case GET_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        companies: {
          ...state.companies,

          isFetching: true,
          isFetched: false,
          skipLoading: payload,
        },
      }
    case GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,

        companies: {
          ...state.companies,

          isFetching: false,
          isFetched: true,
          isFailed: false,
        },
      }
    case GET_COMPANY_DETAILS_FAILURE:
      return {
        ...state,

        companies: {
          ...state.companies,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    case GETCOMPANIES:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case ADD_COMPANY:
      return {
        ...state,
        addedCompanyId: payload.id,
        company: [
          ...state.company,
          {
            name: payload.name,
            id: payload.id,
            role: 'owner',
          },
        ],
      }
    case CREATE_COMPANY:
      return {
        ...state,
        company_details: payload,
        supportedProviders: getSupported(payload.integrations),
        loading: false,
      }
    case COMPANY_DETAILS:
      // mock cambridge disabled state
      // const providers = payload.integrations.currency_providers
      // const transformedProviders = providers.map(item => {
      //   if (item.type === 'cambridge') {
      //     return {
      //       ...item,

      //       state: 'disabled',
      //       properties: {
      //         ...item.properties,

      //         status: `Onboarding Id doesn't exist`,
      //       },
      //     }
      //   } else {
      //     return item
      //   }
      // })
      return {
        ...state,
        // company_details: {
        //   ...payload,

        //   integrations: {
        //     ...payload.integrations,

        //     currency_providers: transformedProviders,
        //   },
        // },
        company_details: payload,
        supportedProviders: getSupported(payload.integrations),
        loading: false,
      }
    case UPDATE_COMPANY:
      return {
        ...state,
        company_details: {
          ...payload,
          limitations: state.company_details.limitations,
          integrations: state.company_details.integrations,
          members: state.company_details.members,
        },
        loading: false,
      }
    case TOGGLE_EDIT:
      return {
        ...state,
        isEdit: payload,
      }
    case TOGGLE_COMPANY_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case CLEAR_COMPANY_INFO:
      return {
        ...state,
        company: [],
        company_details: {},
        loading: false,
        isEdit: false,
        addedCompanyId: '',
      }
    default:
      return state
  }
}

export default company
