const initialState = {
  isDarkMode:true
};

const theme = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "TOGGLE_THEME":
      return {
        ...state, 
        isDarkMode:payload
      };
    default:
      return state;
  }
}

export default theme;