export const bgAuth = {
  styles: {
    global: (props) => ({
      body: {
        bg: '#1E2024',
        bgSize: "cover",
        bgPosition: "center center",
      },
    }),
  },
};
