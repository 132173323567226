import React, { forwardRef, useCallback } from 'react'
import { useSnackbar, SnackbarContent } from 'notistack'
import { Alert, AlertIcon, Box, CloseButton } from '@chakra-ui/react'

const NotifierComponent = forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id)
  }, [props.id, closeSnackbar])

  return (
    <SnackbarContent ref={ref}>
      <Box minW='200px' maxW='450px' w='100%'>
        <Alert
          key={props.id}
          id={props.id}
          status={props.type}
          data-role={props.type}
          variant='solid'
        >
          <AlertIcon />
          <Box w='82%'>{props.message}</Box>
          <CloseButton
            alignSelf='flex-start'
            position='absolute'
            right='5px'
            top='5px'
            onClick={handleDismiss}
          />
        </Alert>
      </Box>
    </SnackbarContent>
  )
})

export default NotifierComponent
