export const TOGGLE_THEME = 'TOGGLE_THEME'

export const SET_INITIALIZATION = 'SET_INITIALIZATION'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS'
export const PASSWORD_CHANGE_FAIL = 'PASSWORD_CHANGE_FAIL'
export const REQUEST_PASSWORD_CHANGE_SUCCESS = 'REQUEST_PASSWORD_CHANGE_SUCCESS'
export const REQUEST_PASSWORD_CHANGE_FAIL = 'REQUEST_PASSWORD_CHANGE_FAIL'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const RECONNECT_ON_REFRESH = 'RECONNECT_ON_REFRESH'
export const EXPIRED_PASSWORD = 'EXPIRED_PASSWORD'

export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS'
export const EMAIL_VERIFY_FAIL = 'EMAIL_VERIFY_FAIL'
export const PHONE_VERIFY_SUCCESS = 'PHONE_VERIFY_SUCCESS'
export const PHONE_VERIFY_FAIL = 'PHONE_VERIFY_FAIL'
export const ONBOARD_LOADING = 'ONBOARD_LOADING'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const SET_EMAIL = 'SET_EMAIL'
export const CLEAR_ONBOARDING = 'CLEAR_ONBOARDING'

export const USERINFO = 'USERINFO'
export const GETCOMPANIES = 'GETCOMPANIES'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const ADD_COMPANY = 'ADD_COMPANY'
export const COMPANY_DETAILS = 'COMPANY_DETAILS'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const TOGGLE_EDIT = 'TOGGLE_EDIT'
export const CLEAR_COMPANY_INFO = 'CLEAR_COMPANY_INFO'
export const TOGGLE_COMPANY_LOADING = 'TOGGLE_COMPANY_LOADING'

export const MONEYCORP_CONNECTED = 'MONEYCORP_CONNECTED'
export const MONEYCORP_DISCONNECTED = 'MONEYCORP_DISCONNECTED'
export const CLEAR_MONEYCORP = 'CLEAR_MONEYCORP'

export const GET_BENEFICIARIES = 'GET_BENEFICIARIES'
export const CREATE_BENEFICIARY_REQUEST = 'CREATE_BENEFICIARY_REQUEST'
export const CREATE_BENEFICIARY_SUCCESS = 'CREATE_BENEFICIARY_SUCCESS'
export const CREATE_BENEFICIARY_FAILURE = 'CREATE_BENEFICIARY_FAILURE'
export const GET_BENEFICIARY_TEMPLATES = 'GET_BENEFICIARY_TEMPLATES'
export const RESET_BENEFICIARY_TEMPLATES = 'RESET_BENEFICIARY_TEMPLATES'
export const FETCH_BENEFICIARY_DETAILS_REQUEST =
  'FETCH_BENEFICIARY_DETAILS_REQUEST'
export const FETCH_BENEFICIARY_DETAILS_SUCCESS =
  'FETCH_BENEFICIARY_DETAILS_SUCCESS'
export const FETCH_BENEFICIARY_DETAILS_FAILURE =
  'FETCH_BENEFICIARY_DETAILS_FAILURE'
export const CLEAR_BENEFICIARY_DETAILS = 'CLEAR_BENEFICIARY_DETAILS'
export const CLEAR_BENEFICIARIES = 'CLEAR_BENEFICIARIES'
export const TOGGLE_BENEFICIARY_LOADING = 'TOGGLE_BENEFICIARY_LOADING'
export const TOGGLE_BENEFICIARY_CREATING = 'TOGGLE_BENEFICIARY_CREATING'
export const SET_BENEFICIARY_ROWS = 'SET_BENEFICIARY_ROWS'
export const SET_BENEFICIARY_PAGE = 'SET_BENEFICIARY_PAGE'
export const GET_ALL_BENEFICIARIES = 'GET_ALL_BENEFICIARIES'
export const FETCH_BENEFICIARIES_BY_SYM_REQUEST =
  'FETCH_BENEFICIARIES_BY_SYM_REQUEST'
export const FETCH_BENEFICIARIES_BY_SYM_SUCCESS =
  'FETCH_BENEFICIARIES_BY_SYM_SUCCESS'
export const FETCH_BENEFICIARIES_BY_SYM_FAILURE =
  'FETCH_BENEFICIARIES_BY_SYM_FAILURE'
export const INIT_PAYMENT_BENEFICIARIES = 'INIT_PAYMENT_BENEFICIARIES'
export const SET_BENEFICIARY_SEARCH = 'SET_BENEFICIARY_SEARCH'
export const SET_BENEFICIARY_CURRENCY_FILTER = 'SET_BENEFICIARY_CURRENCY_FILTER'
export const RESET_FORM = 'RESET_FORM'

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const GET_CONVERSIONS = 'GET_CONVERSIONS'
export const GET_CONVERSIONS_ERROR = 'GET_CONVERSIONS_ERROR'
export const CREATE_CONVERSION = 'CREATE_CONVERSION'
export const GET_CONVERSION_DETAILS = 'GET_CONVERSION_DETAILS'
export const CLEAR_CONVERSION_DETAILS = 'CLEAR_CONVERSION_DETAILS'
export const CLEAR_CONVERSIONS = 'CLEAR_CONVERSIONS'
export const CONVERSION_REASONS = 'CONVERSION_REASONS'
export const GET_QUOTE = 'GET_QUOTE'
export const CLEAR_QUOTE = 'CLEAR_QUOTE'
export const GET_QUOTE_ERROR = 'GET_QUOTE_ERROR'
export const INVALIDATE_QUOTE = 'INVALIDATE_QUOTE'
export const CREATE_CONVERSION_ERROR = 'CREATE_CONVERSION_ERROR'
export const CREATE_CONVERSION_LOADING = 'CREATE_CONVERSION_LOADING'
export const SET_QUOTE_LOADING = 'SET_QUOTE_LOADING'
export const SET_CONVERSION_FILTER = 'SET_CONVERSION_FILTER'
export const CLEAR_CONVERSION_FILTER = 'CLEAR_CONVERSION_FILTER'
export const SET_ROWS_FILTER = 'SET_ROWS_FILTER'
export const SET_PAGES_FILTER = 'SET_PAGES_FILTER'
export const GET_NONE_CONVERSION_DTS = 'GET_NONE_CONVERSION_DTS'

export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_PAYMENTS_ERROR = 'GET_PAYMENTS_ERROR'
export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS'
export const CREATE_TRANSFER = 'CREATE_TRANSFER'
export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST'
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE'
export const FUND_WALLET = 'FUND_WALLET'
export const SET_PAYMENT_FILTER = 'SET_PAYMENT_FILTER'
export const CLEAR_PAYMENT_FILTER = 'CLEAR_PAYMENT_FILTER'
export const SET_PAYMENTROWS_FILTER = 'SET_PAYMENTROWS_FILTER'
export const SET_PAYMENTPAGES_FILTER = 'SET_PAYMENTPAGES_FILTER'
export const SET_PAYMENT_LOADING = 'SET_PAYMENT_LOADING'
export const GET_NONE_PAYMENT_DATES = 'GET_NONE_PAYMENT_DATES'
export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS'
export const CLEAR_ITEMS = 'CLEAR_ITEMS'
export const SET_PAYMENT_PARAMS = 'SET_PAYMENT_PARAMS'
export const CLEAR_PAYMENT_DETAILS = 'CLEAR_PAYMENT_DETAILS'

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_BALANCES = 'GET_BALANCES'
export const GET_BALANCES_ERROR = 'GET_BALANCES_ERROR'
export const GET_BALANCE = 'GET_BALANCE'
export const CLEAR_BALANCES = 'CLEAR_BALANCES'
export const SET_BALANCES_LOADING = 'SET_BALANCES_LOADING'

export const GET_LOCKRATES = 'GET_LOCKRATES'
export const GET_LOCKRATES_ERROR = 'GET_LOCKRATES_ERROR'
export const CREATE_LOCKRATE_REQUEST = 'CREATE_LOCKRATE_REQUEST'
export const CREATE_LOCKRATE_SUCCESS = 'CREATE_LOCKRATE_SUCCESS'
export const GET_LOCKRATE_DETAILS = 'GET_LOCKRATE_DETAILS'
export const CLEAR_LOCKRATE_DETAILS = 'CLEAR_LOCKRATE_DETAILS'
export const CLEAR_LOCKRATES = 'CLEAR_LOCKRATES'
export const LOCKRATE_REASONS = 'LOCKRATE_REASONS'
export const GET_LOCKRATE_QUOTE = 'GET_LOCKRATE_QUOTE'
export const CLEAR_LOCKRATE_QUOTE = 'CLEAR_LOCKRATE_QUOTE'
export const GET_LOCKRATE_QUOTE_ERROR = 'GET_LOCKRATE_QUOTE_ERROR'
export const CREATE_LOCKRATE_FAILURE = 'CREATE_LOCKRATE_FAILURE'
export const CREATE_LOCKRATE_LOADING = 'CREATE_LOCKRATE_LOADING'
export const SET_LOCKRATE_FILTER = 'SET_LOCKRATE_FILTER'
export const CLEAR_LOCKRATE_FILTER = 'CLEAR_LOCKRATE_FILTER'
export const SET_LOCKRATE_ROWS_FILTER = 'SET_LOCKRATE_ROWS_FILTER'
export const SET_LOCKRATE_PAGES_FILTER = 'SET_LOCKRATE_PAGES_FILTER'
export const GET_NONE_CONVERSION_DATES = 'GET_NONE_CONVERSION_DATES'
export const SET_LOCKRATE_PARAMS = 'SET_LOCKRATE_PARAMS'
export const INVALIDATE_LOCKRATE_QUOTE = 'INVALIDATE_LOCKRATE_QUOTE'

export const GET_OVERVIEW = 'GET_OVERVIEW'
export const FETCH_OVERVIEW_REQUEST = 'FETCH_OVERVIEW_REQUEST'
export const GET_OVERVIEW_ERROR = 'GET_OVERVIEW_ERROR'
export const SET_OVERVIEW_LOADING = 'SET_OVERVIEW_LOADING'
export const CLEAR_OVERVIEW = 'CLEAR_OVERVIEW'

export const GET_AUTOMATIONS = 'GET_AUTOMATIONS'
export const GET_AUTOMATIONS_ERROR = 'GET_AUTOMATIONS_ERROR'
export const GET_AUTOMATION_DETAILS = 'GET_AUTOMATION_DETAILS'
export const CREATE_AUTOMATION_REQUEST = 'CREATE_AUTOMATION_REQUEST'
export const CREATE_AUTOMATION_SUCCESS = 'CREATE_AUTOMATION_SUCCESS'
export const CREATE_AUTOMATION_FAILURE = 'CREATE_AUTOMATION_FAILURE'
export const CREATE_AUTOMATION_LOADING = 'CREATE_AUTOMATION_LOADING'
export const GET_AUTOMATION_CURRENCIES = 'GET_AUTOMATION_CURRENCIES'
export const GET_AUTOMATION_QUOTE = 'GET_AUTOMATION_QUOTE'
export const CLEAR_AUTOMATION_QUOTE = 'CLEAR_AUTOMATION_QUOTE'
export const DELETE_AUTOMATION = 'DELETE_AUTOMATION'
export const UPDATE_AUTOMATION = 'UPDATE_AUTOMATION'
export const UPDATE_AUTOMATION_ERROR = 'UPDATE_AUTOMATION_ERROR'
export const CLEAR_AUTOMATIONS = 'CLEAR_AUTOMATIONS'
export const CLEAR_AUTOMATION_DETAILS = 'CLEAR_AUTOMATION_DETAILS'
export const SET_AUTOMATIONS_FILTER = 'SET_AUTOMATIONS_FILTER'
export const SET_AUTOMATIONS_ROWS_FILTER = 'SET_AUTOMATIONS_ROWS_FILTER'
export const SET_AUTOMATIONS_PAGES_FILTER = 'SET_AUTOMATIONS_PAGES_FILTER'
export const GET_HISTORICAL_PRICES = 'GET_HISTORICAL_PRICES'
export const FETCH_AUTOMATION_BILLS_REQUEST = 'FETCH_AUTOMATION_BILLS_REQUEST'
export const FETCH_AUTOMATION_BILLS_SUCCESS = 'FETCH_AUTOMATION_BILLS_SUCCESS'
export const FETCH_AUTOMATION_BILLS_FAILURE = 'FETCH_AUTOMATION_BILLS_FAILURE'

export const GET_QUICKBOOKSURL = 'GET_QUICKSBOOKSURL'
export const SET_QUICKBOOK_STATUS = 'SET_QUICKBOOK_STATUS'
export const CONNECT_QUICKBOOKS = 'CONNECT_QUICKBOOKS'
export const CONNECT_QUICKBOOKS_ERROR = 'CONNECT_QUICKBOOKS_ERROR'
export const CLEAR_QUICKBOOKS = 'CLEAR_QUICKBOOKS'
export const LOADING_QUICKBOOKS = 'LOADING_QUICKBOOKS'
export const DISCONNECT_QUICKBOOKS = 'DISCONNECT_QUICKBOOKS'
export const DISCONNECT_QUICKBOOKS_ERROR = 'DISCONNECT_QUICKBOOKS_ERROR'

export const GET_BILLS = 'GET_BILLS'
export const GET_BILLS_ERROR = 'GET_BILLS_ERROR'
export const GET_BILL_DETAILS = 'GET_BILL_DETAILS'
export const SET_BILL_LOADING = 'SET_BILL_LOADING'
export const CLEAR_BILLS = 'CLEAR_BILLS'
export const CLEAR_BILL_DETAILS = 'CLEAR_BILL_DETAILS'
export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS'
export const CLEAR_VENDOR_DETAILS = 'CLEAR_VENDOR_DETAILS'
export const GET_BILL_QUOTE = 'GET_BILL_QUOTE'
export const CLEAR_BILL_QUOTE = 'CLEAR_BILL_QUOTE'
export const SET_UPCOMING_BILLS = 'SET_UPCOMING_BILLS'
export const CLEAR_UPCOMING_BILLS = 'CLEAR_UPCOMING_BILLS'
export const CLEAR_HISTORICAL_PRICES = 'CLEAR_HISTORICAL_PRICES'

export const SET_BILL_FILTER = 'SET_BILL_FILTER'
export const CLEAR_BILL_FILTER = 'CLEAR_BILL_FILTER'
export const SET_BILL_ROWS_FILTER = 'SET_BILL_ROWS_FILTER'
export const SET_BILL_PAGES_FILTER = 'SET_BILL_PAGES_FILTER'

export const SET_BENEFICIARY_ROWS_FILTER = 'SET_BENEFICIARY_ROWS_FILTER'
export const SET_BENEFICIARY_PAGES_FILTER = 'SET_BENEFICIARY_PAGES_FILTER'
export const SET_BENEFICIARY_FILTER = 'SET_BENEFICIARY_FILTER'

export const SET_SESSION_EXPIRATION = 'SET_SESSION_EXPIRATION'
export const SET_SESSION_IDLE = 'SET_SESSION_IDLE '
export const SET_SESSION_LAST_ACTIVITY_TIME = 'SET_SESSION_LAST_ACTIVITY_TIME'

export const GET_REASONS = 'GET_REASONS'
export const GET_REASONS_CACHE = 'GET_REASONS_CACHE'
export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_CURRENCIES_CACHE = 'GET_CURRENCIES_CACHE'
export const GET_CURRENCIES_PINGPONG = 'GET_CURRENCIES_PINGPONG'
export const GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG =
  'GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG'

export const SET_CURRENCY_PROVIDER = 'SET_CURRENCY_PROVIDER'
export const SET_PINGPONG_LOADING = 'SET_PINGPONG_LOADING'
export const CONNECT_PINGPONG = 'CONNECT_PINGPONG'
export const DISCONNECT_PINGPONG = 'DISCONNECT_PINGPONG'
export const CONNECT_PINGPONG_ERROR = 'CONNECT_PINGPONG_ERROR'
export const CLEAR_PINGPONG = 'CLEAR_PINGPONG'
export const SET_PINGPONG_TEMPLATE = 'SET_PINGPONG_TEMPLATE'
export const SET_PINGPONG_TEMPLATE_ERROR = 'SET_PINGPONG_TEMPLATE_ERROR'

export const GET_RECEIVING_ACCOUNTS = 'GET_RECEIVING_ACCOUNTS'
export const GET_RECEIVING_ACCOUNTS_ERROR = 'GET_RECEIVING_ACCOUNTS_ERROR'
export const GET_RECEIVING_ACCOUNTS_TEMPLATES =
  'GET_RECEIVING_ACCOUNTS_TEMPLATES'
export const RECEIVING_ACCOUNTS_LOADING = 'RECEIVING_ACCOUNTS_LOADING'

export const CAMBRIDGE_CONNECTION_STATUS = 'CAMBRIDGE_CONNECTION_STATUS'
export const GET_CURRENCIES_CAMBRIDGE = 'GET_CURRENCIES_CAMBRIDGE'
export const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS'
export const FETCH_TEMPLATE_REQUEST = 'FETCH_TEMPLATE_REQUEST'
export const FETCH_TEMPLATE_FAILURE = 'FETCH_TEMPLATE_FAILURE'
export const RESET_CAMBRIDGE_TEMPLATE = 'RESET_CAMBRIDGE_TEMPLATE'
export const RESET_CAMBRIDGE_PAYMENTS = 'RESET_CAMBRIDGE_PAYMENTS'
export const GET_CAMBRIDGE_WALLETS = 'GET_CAMBRIDGE_WALLETS'
export const GET_CAMBRIDGE_SETTLEMENTS = 'GET_SETTLEMENTS'
export const GET_CAMBRIDGE_PAYMENT_METHODS = 'GET_CAMBRIDGE_PAYMENT_METHODS'
export const CLEAR_CAMBRIDGE = 'CLEAR_CAMBRIDGE'

export const CONNECT_OFX = 'CONNECT_OFX'
export const DISCONNECT_OFX = 'DISCONNECT_OFX'
export const DISCONNECT_CAMBRIDGE = 'DISCONNECT_CAMBRIDGE'
export const GET_OFX_CONVERSON_CURRENCIES = 'GET_OFX_CONVERSON_CURRENCIES'
export const GET_OFX_PAYMENT_CURRENCIES = 'GET_OFX_PAYMENT_CURRENCIES'
export const GET_OFX_ACCOUNTS = 'GET_OFX_ACCOUNTS'
export const GET_OFX_ACCOUNTS_FAILURE = 'GET_OFX_ACCOUNTS_FAILURE'
export const GET_OFX_CLASSIFICATION = 'GET_OFX_CLASSIFICATION'
export const CLEAR_OFX = 'CLEAR_OFX'

export const CONNECT_CAMBRIDGE_REQUEST = 'CONNECT_CAMBRIDGE_REQUEST'
export const CONNECT_CAMBRIDGE_SUCCESS = 'CONNECT_CAMBRIDGE_SUCCESS'
export const CONNECT_CAMBRIDGE_FAILURE = 'CONNECT_CAMBRIDGE_FAILURE'

//feature flags
export const FEATURE_OFX_TOGGLE = 'FEATURE_OFX_TOGGLE'
export const FEATURE_CAMBRIDGE_TOGGLE = 'FEATURE_CAMBRIDGE_TOGGLE'
export const FEATURE_PINGPONG_TOGGLE = 'FEATURE_PINGPONG_TOGGLE'
export const SET_OFX_TEMPLATE = 'SET_OFX_TEMPLATE'
export const RESET_OFX_TEMPLATE = 'RESET_OFX_TEMPLATE'

export const RECAPTCHA_FAIL = 'RECAPTCHA_FAIL'
export const RECAPTCHA_FAIL_REGISTER = 'RECAPTCHA_FAIL_REGISTER'

export const CLEAR_STORE = 'CLEAR_STORE'

export const UPDATE_VENDOR_REQUEST = 'UPDATE_VENDOR_REQUEST'
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS'
export const UPDATE_VENDOR_FAILURE = 'UPDATE_VENDOR_FAILURE'
export const UPDATE_VENDOR_RESET = 'UPDATE_VENDOR_RESET'

export const GET_COMPANY_DETAILS_REQUEST = 'GET_COMPANY_DETAILS_REQUEST'
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS'
export const GET_COMPANY_DETAILS_FAILURE = 'GET_COMPANY_DETAILS_FAILURE'

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const DELETE_TABLE_ROW_REQUEST = 'DELETE_TABLE_ROW_REQUEST'
export const DELETE_TABLE_ROW_SUCCESS = 'DELETE_TABLE_ROW_SUCCESS'
export const DELETE_TABLE_ROW_FAILURE = 'DELETE_TABLE_ROW_FAILURE'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const REMOVE_TEMPLATE_ERROR = 'REMOVE_TEMPLATE_ERROR'

export const SET_USERS_DATA = 'SET_USERS_DATA'

export const SAVE_SIGNALR_MESSAGE = 'SAVE_SIGNALR_MESSAGE'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const DELETE_SIGNALR_MESSAGE = 'DELETE_SIGNALR_MESSAGE'
export const DELETE_SIGNALR_MESSAGES = 'DELETE_SIGNALR_MESSAGES'
export const CURRENT_SIGNALR_MESSAGES = 'CURRENT_SIGNALR_MESSAGES'
export const DELETE_CURRENT_SIGNALR_MESSAGE = 'DELETE_CURRENT_SIGNALR_MESSAGE'
export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST'
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS'
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE'

export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST'
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS'
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE'

export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST'
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS'
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE'

export const SEND_INVITATION_REQUEST = 'SEND_INVITATION_REQUEST,'
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS'
export const SEND_INVITATION_FAILURE = 'SEND_INVITATION_FAILURE'

export const CANCEL_INVITATION_REQUEST = 'CANCEL_INVITATION_REQUEST'
export const CANCEL_INVITATION_SUCCESS = 'CANCEL_INVITATION_SUCCESS'
export const CANCEL_INVITATION_FAILURE = 'CANCEL_INVITATION_FAILURE'

export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST'
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS'
export const RESEND_INVITATION_FAILURE = 'RESEND_INVITATION_FAILURE'

export const CLEAR_INVITATION_STATE = 'CLEAR_INVITATION_STATE'
export const SKIP_OFX_REAUTHENTICATE = 'SKIP_OFX_REAUTHENTICATE'

export const INITIALTE_PHONE_VERIFY_REQUEST = 'INITIALTE_PHONE_VERIFY_REQUEST'
export const INITIALTE_PHONE_VERIFY_SUCCESS = 'INITIALTE_PHONE_VERIFY_SUCCESS'
export const INITIALTE_PHONE_VERIFY_FAILURE = 'INITIALTE_PHONE_VERIFY_FAILURE'

export const GET_COREPAY_OPTIONS_REQUST = 'GET_COREPAY_OPTIONS_REQUST'
export const GET_COREPAY_OPTIONS_SUCCESS = 'GET_COREPAY_OPTIONS_SUCCESS'
export const GET_COREPAY_OPTIONS_FAILURE = 'GET_COREPAY_OPTIONS_FAILURE'

export const CREATE_CORPAY_ONBOARDING_APP_REQUEST =
  'CREATE_CORPAY_ONBOARDING_APP_REQUEST'
export const CREATE_CORPAY_ONBOARDING_APP_SUCCESS =
  'CREATE_CORPAY_ONBOARDING_APP_SUCCESS'
export const CREATE_CORPAY_ONBOARDING_APP_FAILURE =
  'CREATE_CORPAY_ONBOARDING_APP_FAILURE'

export const GET_COREPAY_REQUIRED_FILES_REQUST =
  'GET_COREPAY_REQUIRED_FILES_REQUST'
export const GET_COREPAY_REQUIRED_FILES_SUCCESS =
  'GET_COREPAY_REQUIRED_FILES_SUCCESS'
export const GET_COREPAY_REQUIRED_FILES_FAILURE =
  'GET_COREPAY_REQUIRED_FILES_FAILURE'

export const EDIT_UPLOADED_DOCUMENTS = 'EDIT_UPLOADED_DOCUMENTS'
export const GET_LOCKRATE_GUIDELINES_REQUEST = 'GET_LOCKRATE_GUIDELINES_REQUEST'
export const GET_LOCKRATE_GUIDELINES_SUCCESS = 'GET_LOCKRATE_GUIDELINES_SUCCESS'
export const GET_LOCKRATE_GUIDELINES_FAILURE = 'GET_LOCKRATE_GUIDELINES_FAILURE'

export const SET_CORRELATION_ID = 'SET_CORRELATION_ID'
